var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4.18.3"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import auth from 'auth';
import { appVersion } from 'consts/app';
import { getShopKey } from '@providers/ConfigProvider';
import { setCaptureExceptionFunc } from 'utils/captureException';
import resolveSentryEnv from 'utils/resolveSentryEnv';

const getReplaysSessionSampleRate = (shopId, defaultValue) => {
  try {
    const replaysSampleRates = JSON.parse(process.env.NEXT_PUBLIC_REPLAY_SESSION_SAMPLE_RATES);
    return replaysSampleRates[shopId] || defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

const initSentry = async () => {
  const replaysSessionSampleRate = parseFloat(process.env.NEXT_PUBLIC_REPLAY_SESSION_SAMPLE_RATE) || 0.0;

  if (process.env.NEXT_PUBLIC_FULL_SENTRY_ENABLED === 'true') {
    const { init, setUser, setContext, setTag, getCurrentHub, captureException, Replay } = await import(
      '@sentry/nextjs'
    );

    const integrations = [new Replay({ maskAllText: false, blockAllMedia: false })];

    if (process.env.NEXT_PUBLIC_SENTRY_PROFILING_ENABLED === 'true') {
      const { BrowserProfilingIntegration } = await import('@sentry/nextjs');
      integrations.push(new BrowserProfilingIntegration());
    }

    init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: 'unknown',
      release: `v${appVersion}`,
      replaysSessionSampleRate,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      profilesSampleRate: 1.0,
      ignoreErrors: ['top.GLOBALS', 'fb_xd_fragment', 'ResizeObserver loop'],
      integrations,
    });

    auth.subscribe(({ shopId }) => {
      getCurrentHub().getClient().getOptions().replaysSessionSampleRate = getReplaysSessionSampleRate(
        shopId,
        replaysSessionSampleRate
      );
    });

    const fillContext = () => {
      if (auth.userId) {
        setUser({ id: `${auth.userId}` });
      }
      if (auth.shopId) {
        setContext('shop', { id: auth.shopId, name: getShopKey(auth.shopId) });
        setTag('shop', getShopKey(auth.shopId));
      }
      if (auth.claims) {
        setContext('auth', auth.claims);
      }
    };

    setCaptureExceptionFunc(async (err) => {
      getCurrentHub().getClient().getOptions().environment = await resolveSentryEnv();
      fillContext();
      captureException(err);
    });

    window.addEventListener('error', async (event) => {
      getCurrentHub().getClient().getOptions().environment = await resolveSentryEnv();
      event.preventDefault();
      fillContext();
      captureException(event);
    });

    window.addEventListener('unhandledrejection', async (event) => {
      getCurrentHub().getClient().getOptions().environment = await resolveSentryEnv();
      event.preventDefault();
      fillContext();
      captureException(event.reason);
    });
  } else {
    const { BrowserMicroSentryClient, BreadcrumbPlugin } = await import('@micro-sentry/browser');

    const client = new BrowserMicroSentryClient({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: 'unknown',
      release: `v${appVersion}`,
      replaysSessionSampleRate,
      replaysOnErrorSampleRate: 1.0,
      plugins: [BreadcrumbPlugin],
      ignoreErrors: ['top.GLOBALS', 'fb_xd_fragment', 'ResizeObserver loop'],
    });

    auth.subscribe(({ shopId }) => {
      client.replaysSessionSampleRate = getReplaysSessionSampleRate(shopId, replaysSessionSampleRate);
    });

    const fillContext = () => {
      if (auth.userId) {
        client.setUser({ id: `${auth.userId}` });
      }
      if (auth.shopId) {
        client.setExtra('shop', { id: auth.shopId, name: getShopKey(auth.shopId) });
        client.setTag('shop', getShopKey(auth.shopId));
      }
      if (auth.claims) {
        client.setExtra('auth', auth.claims);
      }
    };

    setCaptureExceptionFunc(async (err) => {
      client.environment = await resolveSentryEnv();
      fillContext();
      client.report(err);
    });

    window.addEventListener('error', async (event) => {
      client.environment = await resolveSentryEnv();
      event.preventDefault();
      fillContext();
      client.report(event);
    });

    window.addEventListener('unhandledrejection', async (event) => {
      client.environment = await resolveSentryEnv();
      event.preventDefault();
      fillContext();
      client.report(event.reason);
    });
  }
};

initSentry();
