import ContactConfig from 'types/ContactConfig';

const redarcContact: ContactConfig = {
  appName: 'Redarc.pl',
  appShortName: 'Redarc',
  name: 'COCON',
  typeOfCompany: 'S.A.',
  typeOfCompanyFull: 'Spółka akcyjna',
  companyUrl: 'https://cocon.pl',
  bankName: 'ING Bank Śląski S.A.',
  bankNumber: '64 1050 1504 1000 0090 3174 6812',
  bankData: 'IBAN: PL, SWIFT: INGBPLPW',
  servicePhoneNumber: '',
  faxNumber: '',
  landlinePhone: 343448558, // todo phone
  contactEmail: 'kontakt@redarc.pl',
  officeEmail: 'biuro@cocon.pl',
  pageEmail: 'biuro@cocon.pl',
  links: ['http://bit.ly/COCONLIN'],
  address: 'ul. Dworcowa 10A, 46-300 Olesno, Polska',
  addressWithoutCountry: 'ul. Dworcowa 10A, 46-300 Olesno',
  nip: '5761586447',
  regon: '382294250',
  krs: '0001066701',
  shortAddress: '46-300 Olesno, Dworcowa 10a',
  openingHours: 'PN - PT 8:00 - 16:00',
  fullInfo:
    'COCON S.A. ul. Dworcowa 10a, 46-300 Olesno, zarejestrowana w Sądzie Rejonowym w Opolu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0001066701, NIP 5761586447.',
  registerInfo:
    'Wpis do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzony przez Sąd Rejonowy w Opolu, VIII\nWydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0001066701',
};

export { redarcContact };
